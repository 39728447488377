import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import request from 'superagent';

import parseJSON from 'shared-utils/src/parseJSON';
import addToRecentlySearchedLocalities from 'shared-utils/src/addToRecentlySearchedLocalities';

import RESUME_LS_RECORD from 'shared-constants/src/resumeLSRecord';
import PAGES_TYPE from 'shared-constants/src/pagesType';
import MAP_LOCALITY from 'shared-constants/src/mapGenericLocality';
import { DEFAULT_FILTERS_CONFIGURATION } from 'shared-constants/src/filters';

import SearchBuilder from 'shared-components/components/SearchBuilder';
import defaultFiltersForSearchBuilder from 'shared-constants/src/defaultFiltersForSearchBuilder';

import { getItem, setItem } from '@helpers/Storage';

import { fetchHome } from '../../common/redux/home/actions';

import UiHandlerHOC from '../app/UiHandler';
import {
  fetchLocationById,
  manageBuildingCondition,
} from '../components/common/search/helpers/search';

import {
  saveRecentSearchesEntryHP,
  normalizeSRPUrl,
} from '../helpers/search/Search';

import { getUserSearchHistory } from '../helpers/MyCasaHelpers';

import Home from './Home';


const checkUsableFiltersValidity = (filters) => {
  const usableCategoryValues = DEFAULT_FILTERS_CONFIGURATION.category.values.map(v => v.value);
  const usableChannelValues = DEFAULT_FILTERS_CONFIGURATION.channel.values.map(v => v.value);

  if (!usableCategoryValues.includes(filters.category)) {
    filters.category = defaultFiltersForSearchBuilder.category;
  }
  if (!usableCategoryValues.includes(filters.propertyTypeGroup)) {
    filters.propertyTypeGroup = defaultFiltersForSearchBuilder.category;
  }
  if (!usableChannelValues.includes(filters.channel)) {
    filters.channel = defaultFiltersForSearchBuilder.channel;
  }

  /**
   * TECH-7028 - air_conditioned is a boolean, but it's passed as an array in old configuration
   * so we need to convert it to a boolean and set it to false if it's an array with only 'all' value
   * TODO: remove this when localStorage will be removed with this configuration
   */
  if (Array.isArray(filters.air_conditioned)) {
    if (filters.air_conditioned.length === 1 && filters.air_conditioned[0] === 'all') {
      filters.air_conditioned = false;
    } else {
      filters.air_conditioned = true;
    }
  }


};

const HomePageComponent = (props) => {
  const {
    config: {
      esapi,
      siteName,
      layout: {
        recentSearchesLocalStorageKey,
      },
    },
    home: {
      filters,
      isFetchingResults,
      availableResults,
      availablePoi,
    },
    intl: {
      currentLocale,
    },
    actions: {
      fetchAvailableResults,
    },
    location: {
      pathname,
    },
    isLocalStorageAvailable,
    user,
    navigationCycle,
  } = props;
  
  const [initialFilters, setInitialFilters] = useState({ ...defaultFiltersForSearchBuilder, channel: filters.tr });
  const [initialLocations, setInitialLocations] = useState({
    locations: null,
    parent: null,
  });
  const [nearbyShape, setNearbyShape] = useState(null);
  const [recentSearches, setRecentSearches] = useState(null);
  const [stateUser, setStateUser] = useState({});
  const [available, setAvailable] = useState('');
  const [availablePois, setAvailablePois] = useState(availablePoi);
  const [hasLSSearch, setHasLSSearch] = useState(false);
  const [filtersDialog, setFiltersDialog] = useState(null);
  const [resetDialog, setResetDialog] = useState(null);
  const [mapDialog, setMapDialog] = useState(null);
  const [eraseAddressesDialog, setEraseAddressesDialog] = useState(null);
  
  /** used by FiltersBridge */
  const [tempFilters, setTempFilters] = useState(defaultFiltersForSearchBuilder);
  const [hasEditedtempFilters, setHasEditedTempFilters] = useState(false);
  const updateTempFilters = (newFilter, clb = null) => {
    setTempFilters(state => ({ ...state, ...newFilter }));
    if (!hasEditedtempFilters) {
      setHasEditedTempFilters(true);
    }
    clb && setTimeout(() => clb(), 10);
  };
  /** used by FiltersBridge */

  const computeAPIReq = async ({
    locations,
    filters,
    forceMap = false,
  } = {}) => {
    const { channel, category } = filters;
    const { categoryForLH, building_condition } = manageBuildingCondition(category);
    const apiReqFilters = building_condition !== null
      ? { channel, category: categoryForLH, buildingCondition: building_condition }
      : { channel, category: categoryForLH };

    const proxy = await request.post('/portal-srp/api/v1/apireq')
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .withCredentials()
      .send({
        locations,
        filters: {
          ...filters,
          ...apiReqFilters,
        },
        apireqParams: {
          pathname,
          domain: siteName,
          locale: currentLocale,
          esapi,
          publisherPage: false,
        },
        pageType: forceMap ? PAGES_TYPE.SRP_MAP : PAGES_TYPE.HOME_PAGE,
      });
    const { body: { apireq } } = proxy;
    return apireq;
  };

  const fetchResults = async ({
    locations,
    filters,
  }) => {
    if (!locations.length && !filters.nearby) {
      setAvailable('');
    } else {
      const apireq = await computeAPIReq({
        locations,
        filters,
      });
      fetchAvailableResults(apireq);
    }
  };

  const fetchSBAvailableResults = async ({
    locations,
    filters,
  }) => {
    const apireq = await computeAPIReq({
      locations,
      filters,
    });

    const proxy = await request.post('/portal-srp/api/v1/search/availableresults')
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .withCredentials()
      .send({ apireq: apireq.apireq });

    setAvailablePois(proxy?.body?.availablePoi || []);

    return {
      total: proxy?.body?.total || 0,
      ptgrpsAggregates: proxy?.body?.ptgrpsAggregates || null,
      nearbyGeoJSON: proxy?.body?.nearbyGeoJSON || null,
    };
  };

  const submitSearch = async ({
    locations,
    parentLocation,
    filters,
    forceMap,
    nearbyShapeQB = null,
  }) => {
    const apireq = await computeAPIReq({
      locations,
      filters,
      forceMap,
    });
    if (isLocalStorageAvailable) {
      // const hasNearby = 'nearby' in filters && filters.nearby;      
      const locationsToUse = (locations.length === 0 && forceMap) || (locations.length === 1 && locations[0].level === 999) // && !hasNearby
        ? [MAP_LOCALITY]
        : locations;

      addToRecentlySearchedLocalities(locationsToUse);
      const rlsObj = { filters, locations: locationsToUse, parentLocation };
      if (nearbyShapeQB) {
        rlsObj.nearbyShape = nearbyShapeQB;
      }
      setItem(RESUME_LS_RECORD, JSON.stringify(rlsObj));
      saveRecentSearchesEntryHP({
        localities: locations,
        parentLocation,
        filters,
        uri: apireq.uri,
        localStorageKey: recentSearchesLocalStorageKey,
      });
    }
    document.location = normalizeSRPUrl(apireq.uri);
  };

  useEffect(
    () => {
      if (window.localStorage) {
        try {
          let lastSearch = parseJSON(getItem(RESUME_LS_RECORD));

          if (!lastSearch) {
            lastSearch = parseJSON(getItem('_newHPLastSearch'));
          } else {
            setHasLSSearch(true);
          }
          const lsRecentSearches = parseJSON(getItem(recentSearchesLocalStorageKey));

          if (lastSearch) {
            const category = lastSearch.filters.propertyTypeGroup || lastSearch.filters.category;

            if (!category || lastSearch.filters.pId) {
              setInitialLocations({
                locations: [],
                parent: {},
              });
            } else {
              const lsFilters = {
                ...lastSearch.filters,
                ...{
                  category,
                },
              };

              if (!lsFilters.propertyTypeGroup) {
                lsFilters.propertyTypeGroup = lsFilters.category;
              }

              checkUsableFiltersValidity(lsFilters);

              const updatedFilters = {
                ...initialFilters,
                ...lsFilters,
              };
              const locations = {
                locations: lastSearch.locations,
                parent: lastSearch.parentLocation || {},
              };

              setInitialFilters(updatedFilters);
              setTempFilters(updatedFilters);
              setInitialLocations(locations);
              setRecentSearches(lsRecentSearches);
              if (lastSearch.nearbyShape) {
                setNearbyShape(lastSearch.nearbyShape);
              }

              fetchResults({
                locations: lastSearch.locations,
                filters: updatedFilters,
              });
            }
          } else if (lsRecentSearches) {
            setInitialLocations({
              locations: [],
              parent: {},
            });
            setRecentSearches(lsRecentSearches);
          }
        } catch (error) {
          console.error('❌ ERRORE durante caricamento dati LS:', error);
        }
      }
    }, 
    // Serve per il BFC.
    [navigationCycle],
  );

  useEffect(() => setStateUser(user), [user]);

  useEffect(
    () => {
      if (stateUser.isAuthenticated) {
        getUserSearchHistory(stateUser, (mycasaRecent) => {
          if (mycasaRecent.length) {
            setRecentSearches(mycasaRecent);
          }
        });
      }
    }, [stateUser],
  );

  useEffect(() => setAvailable(availableResults), [availableResults]);

  return (
    <div key={`search-builder-${navigationCycle}`}>
      <SearchBuilder
        initialFilters={initialFilters}
        initialLocations={initialLocations}
        fetchLocationById={fetchLocationById}
      >
        {
          (searchProps) => {
            const {
              filters,
              locations: {
                search,
                searchParent,
                ui: {
                  main,
                  additional,
                },
              },
              setCurrentNearby,
              setCurrentFilters,
              setCurrentLocation,
            } = searchProps;

            const sbfilters = {
              ...filters,
              ...{ category: filters.propertyTypeGroup || filters.category },
            };

            const setFilters = newFilters => setCurrentFilters(
              { ...filters, ...newFilters },
              updatedFilters => fetchResults({
                locations: search,
                filters: updatedFilters,
              }),
            );

            const getAvailableResults = async ({
              newLocations = null,
              newFilters = {},
            }) => {
              const fetchParams = {
                locations: newLocations || search,
                filters: newFilters || filters,
                // filters: { ...filters, ...newFilters },
              };
              const av = await fetchSBAvailableResults(fetchParams);
              return av;
            };

            const setLocations = (choice, parent) => {
              setCurrentLocation(choice, parent, {}, updatedLocations => fetchResults({
                locations: updatedLocations,
                filters,
              }));
            };
            const setNearby = nearby => setCurrentNearby(nearby, (updatedLocations, updatedParentLocation, updatedFilters) => fetchResults({ locations: updatedLocations, filters: updatedFilters }));

            const shouldForceMap = (qb) => {
              const loc = qb.locations || search;
              return loc.length === 1 && loc[0].level === 999;
            };

            const submit = qb => submitSearch({
              locations: qb.locations || search,
              parentLocation: qb.parentLocation || searchParent,
              filters: qb.filters || filters,
              forceMap: qb.forceMap || shouldForceMap(qb),
              nearbyShapeQB: qb.nearbyShapeQB,
            });

            const resetAll = () => {
              const newFilters = {
                ...filters,
                ...defaultFiltersForSearchBuilder,
              };

              [
                'pId',
                'poi',
                'nearby',
                'geobounds',
                'geocircle',
                'geopolygon',
              ].forEach((f) => {
                if (newFilters[f]) {
                  delete newFilters[f];
                }
              });

              setCurrentFilters(
                newFilters,
                (updatedFilters) => {
                  setCurrentLocation([], null, {}, updatedLocations => fetchResults({ locations: updatedLocations, filters: updatedFilters }));
                  setHasLSSearch(false);
                },
              );
            };

            const startWithSelectedPtypes = initialFilters.propertyTypes && Array.isArray(initialFilters.propertyTypes)
              ? initialFilters.propertyTypes.length > 0
              : false;

            return (
              <Home
                filters={sbfilters}
                locations={search}
                parentLocation={searchParent}
                mainLocation={main}
                additionalLocations={additional}
                nearbyShape={nearbyShape}
                setFilters={setFilters}
                setLocations={setLocations}
                setNearby={setNearby}
                submit={submit}
                resetAll={resetAll}
                getAvailableResults={getAvailableResults}
                recentSearches={recentSearches}
                isFetching={isFetchingResults}
                availableResults={available}
                availablePois={availablePois}
                hasLSSearch={hasLSSearch}
                filtersDialog={filtersDialog}
                setFiltersDialog={setFiltersDialog}
                setResetDialog={setResetDialog}
                setMapDialog={setMapDialog}
                updateTempFilters={updateTempFilters}
                defaultFilters={defaultFiltersForSearchBuilder}
                tempFilters={tempFilters}
                hasEditedtempFilters={hasEditedtempFilters}
                setHasEditedTempFilters={setHasEditedTempFilters}
                startWithSelectedPtypes={startWithSelectedPtypes}
                setEraseAddressesDialog={setEraseAddressesDialog}
                {...props}
              />
            );
          }
        }
      </SearchBuilder>
      {filtersDialog}
      {resetDialog}
      {mapDialog}
      {eraseAddressesDialog}
    </div>
  );
};

HomePageComponent.propTypes = {
  isLocalStorageAvailable: PropTypes.bool,
  actions: PropTypes.instanceOf(Object),
  config: PropTypes.instanceOf(Object),
  search: PropTypes.instanceOf(Object),
  intl: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object),
  home: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  navigationCycle: PropTypes.number,
};

HomePageComponent.defaultProps = {
  isLocalStorageAvailable: false,
  actions: {},
  config: {},
  search: {},
  intl: {},
  location: {},
  home: {},
  user: {},
  navigationCycle: 0,
};

const HomePage = UiHandlerHOC(HomePageComponent, fetchHome);
export default HomePage;
